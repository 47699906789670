import React from "react"
import PropTypes from "prop-types"

function RelatedContent({ header, contentCollection, isLink }) {
  if (!contentCollection || !contentCollection.length) return null

  return (
    <div className="mb-8 mt-2">
      <div className="text-grey-dark font-semibold">{header}</div>

      {contentCollection.map((content) => {
        return isLink ? (
          <a
            className="block text-primary cursor-pointer underline leading-normal mb-1"
            href={content.path}
            target="_blank"
            rel="noreferrer"
            key={content.key}
          >
            <i className="material-icons text-sm mr-2 pt-1">open_in_new</i>
            {content.body}
          </a>
        ) : (
          <div className="task-body offer py-0" key={content.id}>
            {content.body}
          </div>
        )
      })}
    </div>
  )
}

RelatedContent.propTypes = {
  header: PropTypes.string,
  contentCollection: PropTypes.array,
  isLink: PropTypes.bool,
}

export default RelatedContent
