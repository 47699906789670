import React from "react"

const EditIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 2.5L17.5 6.66667L6.66667 17.5H2.5V13.3333L13.3333 2.5Z"
        stroke="#616161"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EditIcon
